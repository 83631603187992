import classNames from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

import styles from './item-card.module.scss';

export const ItemCard = (props) => {
  const { children, lifted, as, label, isExternal, noBorderOnHover, ...rest } = props;
  const Component = as || (isExternal ? 'a' : Link);

  return (
    <Component className={classNames(styles.wrapper, label, lifted && styles.lifted)} {...rest}>
      {!noBorderOnHover && (
        <div className={`${styles.circleWrapper} ${styles.circleWrapper1}`}>
          <div className={styles.circle} />
        </div>
      )}
      {children}
    </Component>
  );
};
