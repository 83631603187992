import { ResourceCard } from 'components/pages/resource-library/resource-card';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';

import styles from './resources.module.scss';

const RESOURCES_NAMES = [
  'solution-sheet',
  'collaborative-white-paper',
  'white-paper',
  'manifesto',
  'technology-radar',
  'carvana',
  'olo',
  'fubotv',
];

const RESOURCES = {
  'solution-sheet': {
    category: 'executive solution sheet',
    title: 'Why Your Organization Should Perform Load Testing',
    link: '/why-your-organization-should-perform-load-testing/',
  },
  'collaborative-white-paper': {
    category: 'cto white paper',
    title: 'Modern Load Testing for Engineering Teams',
    link: '/modern-load-testing-for-engineering-teams/',
  },
  'white-paper': {
    category: 'cto white paper',
    title: 'What to Consider When Building or Buying a Load Testing Solution',
    link: 'https://grafana.com/blog/2024/01/30/load-testing-tools-open-source-considerations-for-homegrown-solutions/',
    isExternal: true,
  },
  manifesto: {
    category: 'manifesto',
    title: 'Developer-centric load testing defined',
    link: '/our-beliefs/',
  },
  'technology-radar': {
    category: 'industry report',
    title: 'k6 in ThoughtWorks Technology Radar',
    link: 'https://www.thoughtworks.com/en-us/radar/tools/k6',
    isExternal: true,
  },
  carvana: {
    category: 'case study',
    title: 'Carvana Speeds Up Test Creation by 100% with k6 Cloud Enterprise',
    link: 'https://grafana.com/success/k6-carvana/',
    isExternal: true,
  },
  olo: {
    category: 'case study',
    title: 'Olo Delivers Faster Testing and Improved Collaboration with k6',
    link: 'https://grafana.com/success/k6-olo/',
    isExternal: true,
  },
  fubotv: {
    category: 'case study',
    title: 'fuboTV Tackles Performance Regressions Before Production with k6 Cloud',
    link: 'https://grafana.com/success/k6-fubotv/',
    isExternal: true,
  },
};

export const Resources = () => {
  const { olo, carvana, fubotv, solutionSheet, collaborativeWhitePaper, whitePaper, manifesto, technologyRadar } =
    useStaticQuery(
      // eslint-disable-next-line no-use-before-define
      IMAGE_QUERY
    );

  RESOURCES.carvana.image = getImage(carvana);
  RESOURCES.olo.image = getImage(olo);
  RESOURCES.fubotv.image = getImage(fubotv);
  RESOURCES['solution-sheet'].image = getImage(solutionSheet);
  RESOURCES['white-paper'].image = getImage(whitePaper);
  RESOURCES['collaborative-white-paper'].image = getImage(collaborativeWhitePaper);
  RESOURCES.manifesto.image = getImage(manifesto);
  RESOURCES['technology-radar'].image = getImage(technologyRadar);

  return (
    <div className={styles.resources}>
      {RESOURCES_NAMES.map((resourceName) => (
        <ResourceCard
          id={resourceName}
          title={RESOURCES[resourceName].title}
          category={RESOURCES[resourceName].category}
          link={RESOURCES[resourceName].link}
          isExternal={RESOURCES[resourceName].isExternal || false}
          image={RESOURCES[resourceName].image}
        />
      ))}
    </div>
  );
};

const IMAGE_QUERY = graphql`
  query {
    carvana: file(absolutePath: { regex: "/carvana-hero@2x.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 535)
      }
    }
    olo: file(absolutePath: { regex: "/olo-delivery-hero.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 535)
      }
    }
    fubotv: file(absolutePath: { regex: "/fubotv-hero.jpeg/" }) {
      childImageSharp {
        gatsbyImageData(width: 535)
      }
    }
    manifesto: file(absolutePath: { regex: "/resources/manifesto.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 535)
      }
    }
    solutionSheet: file(absolutePath: { regex: "/resources/solution-sheet.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 535)
      }
    }
    whitePaper: file(absolutePath: { regex: "/resources/white-paper.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 535)
      }
    }
    collaborativeWhitePaper: file(absolutePath: { regex: "/resources/collaborative-whitepaper.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 535)
      }
    }
    technologyRadar: file(absolutePath: { regex: "/resources/technology-radar.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 535)
      }
    }
  }
`;
