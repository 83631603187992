import { PageInfo } from 'components/shared/page-info';
import React from 'react';

import styles from './resource-library-hero.module.scss';

export const Hero = () => (
  <PageInfo
    className={styles.wrapper}
    title={'Resource Library'}
    description={'Learn more about how to use k6 to ship fast and reliable applications all the time'}
    withBgPattern
  />
);
