import { SEO } from 'components/blocks/seo';
import { Hero } from 'components/pages/resource-library/hero';
import { Resources } from 'components/pages/resource-library/resources';
import { Partners } from 'components/shared/partners';
import { DefaultLayout } from 'layouts/default-layout';
import React from 'react';
import SEO_DATA from 'utils/seo-metadata';

const ResourceLibrary = () => (
  <DefaultLayout>
    <Hero />
    <Resources />
    <Partners
      title={
        // eslint-disable-next-line max-len
        'Engineering teams at the world’s most innovative companies use k6 Cloud to prevent system failures and deliver best-of-class applications'
      }
    />
  </DefaultLayout>
);

export default ResourceLibrary;

export const Head = () => <SEO {...SEO_DATA['resources-library']} />;
