import { Heading } from 'components/shared/heading';
import { ItemCard } from 'components/shared/item-card';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import styles from './resource-card.module.scss';

export const ResourceCard = ({ id, category, title, link, image, isExternal }) => (
  <ItemCard id={id} label={styles.card} to={link} as={isExternal ? 'a' : Link} href={isExternal ? link : undefined}>
    <div className={styles.content}>
      <div className={styles.text}>
        <p className={styles.category}>{category}</p>
        <Heading className={styles.title} tag={'h3'} size={'lg'}>
          {title}
        </Heading>
        <div className={styles.link}>Read more</div>
      </div>

      <div className={styles.image}>
        <GatsbyImage image={image} aspectRatio={67 / 40} />
      </div>
    </div>
  </ItemCard>
);
